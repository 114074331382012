import React, {memo, useCallback, useEffect, useRef} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import classNames from "classnames";
import {useTheme} from "../../../context/themeContext";
import '../GeometryField/geometry-form-field.scss';
import {FormFieldProps} from "../Form/Form";
import {useToastContext} from "../../../context/toastContext";
import {useTranslation} from "react-i18next";
import Button from "../../buttons/Button";
import {AvailableDrawTypes, DrawControlsClass} from "../../../utils/DrawControlsClass";
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectDrawnFeatures} from "../../../redux/selectors/selectors";
import {FeatureProperties} from "../../../redux/map/types";
import {enableEditing} from "../../../redux/draw/draw-reducer";
import {useDispatch} from "react-redux";
import Portal from "../../Portal/Portal";
import EditGeometryToolbar from "../../map/EditGeometryToolbar/EditGeometryToolbar";
import {useLayersInteractivity} from "../../../hooks/map/useLayersInteractivity";


type Props = {
    entityId?: string;
    drawMode: AvailableDrawTypes;
} & FormFieldProps;

const DrawGeometryField = memo(({drawMode, name, value = '', onChange, entityId, ...rest}: Props) => {
    const ref = useRef<any>(null);
    const drawnFeatures = useTypedSelector(selectDrawnFeatures) as GeoJSON.Feature<any, Partial<FeatureProperties>>[];
    const {recoverSettingsAfterDrawing} = useLayersInteractivity();
    const {t} = useTranslation();
    const {theme} = useTheme();
    const {addToast} = useToastContext();
    const dispatch = useDispatch();

    useEffect(() => {
        const event = new Event('change', {bubbles: true});
        ref.current.dispatchEvent(event);
    }, [drawnFeatures]);

    const feature = drawnFeatures?.[0] ? JSON.stringify(drawnFeatures?.[0]) : '';

    const messageByDrawMode: { [key in Exclude<AvailableDrawTypes, 'AllControls'>]: string } = {
        'LineString': t('forms.draw-line-message'),
        // [MapboxDraw.constants.classes.CONTROL_BUTTON_POLYGON]: t('forms.draw-rectangle-message'),
        'Polygon': t('forms.draw-polygon-message'),
        'Point': t('forms.select-stop-message'),
    }

    const className = classNames(
        'text-field-md',
        theme
    );

    const keyPressHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            DrawControlsClass.setDrawControlVisibility({drawType: 'AllControls', visible: false});
        }
    }

    // this is required for triggering a form onChange event is input value is set by state
    useEffect(() => {
        ref.current._valueTracker.getValue = () => {
            return 'fake value';
        }
    }, []);

    useEffect(() => {
        //TODO remove if redirect on /edit is done on feature click?
        dispatch(enableEditing(true));
        document.addEventListener('keyup', keyPressHandler);

        return () => {
            dispatch(enableEditing(false));
            document.removeEventListener('keyup', keyPressHandler);
        }
    }, []);

    const btnClickHandler = useCallback(() => {
        if (!value) {
            addToast(t(messageByDrawMode[drawMode]), "info");
            DrawControlsClass.setDrawControlVisibility({drawType: drawMode, visible: true});
        }
    }, [drawMode, value])

    return (
        <>
            <div className="form-field-md">
                <div className="geometry-form-field">
                    <input
                        name={name}
                        type="text"
                        value={feature ? feature : value}
                        hidden
                        readOnly
                        ref={ref}
                        {...rest}
                    />
                    <input
                        className={className}
                        type="text"
                        value={t(value ? 'forms.geometry-filled-msg' : 'forms.geometry-empty-msg')}
                        readOnly
                        data-testid="drawGeometryFieldVisible"
                    />
                    <Button
                        text={t('forms.set-geometry-btn')}
                        onClick={btnClickHandler}
                        disabled={!!feature || !!value}
                        colorType="transparent"
                        testId="addNewGeometryBtn"
                    />
                </div>
            </div>
            {
                drawnFeatures.length
                    ? <Portal targetNodeId={"map-top-toolbar"}>
                        <EditGeometryToolbar
                            drawnFeatures={drawnFeatures}
                            onDiscardFn={recoverSettingsAfterDrawing}
                        />
                    </Portal>
                    : null
            }
        </>
    )
})

const Labeled = FieldWithLabel<Props>(DrawGeometryField);

export {Labeled, DrawGeometryField}

export default DrawGeometryField;